import * as React from "react"
import { Helmet } from "react-helmet"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"

// import CoverContact from "../Components/CoverContact";
// import ContactFormSection from "../Components/ContactFormSection";
import CoverContactNew from "../Components/CoverContactNew";
import ContactFormNew from "../Components/ContactFormNew";

export default function KontaktPage() {
  return(
    <>
    <Helmet
             bodyAttributes={{
                class: 'navbar--dark'
            }}
            />

      <Layout>
      <Seo title="Kontakt"></Seo>

      <CoverContactNew></CoverContactNew>
      <ContactFormNew></ContactFormNew>
    </Layout>
  </>
  )
}
