import React from 'react';

// Animation - Framer Motion
// import { motion } from "framer-motion"
// import { useScrollAnim } from "./hooks/useScrollAnim"
// import { fadeFast, fadeItem, opacityItem } from "./hooks/animation"

// Assets
// import video from "../Data/images/showreel_web.mp4";
// import image from "../images/img-base/preview-2.jpg";

export default function CoverContact() {

    // Animation based on intersection observer
    // const [element, controls] = useScrollAnim();

    return (
        <section className="cover cover--contact">
            <div className="intro wrap">
                <h1 className="center">Řekněte nám <br></br> o vašem projektu</h1>
                <p className="p-large center">Vyplňte poptávkový formulář níže, napište email nebo klidně rovnou zavolejte.</p>

                <div className="btn-group-center s-btn-group-column">
                <a className="btn-inverse-primary" href="tel:+420737090913">+420 737 090 913</a>
                <a className="btn-inverse-primary" href="mailto:info@revealmedia.cz">info@reveal.cz</a>
                </div>
            </div>
        </section>
    )
}
