import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";

export default function ContactFormSection() {

    const [submitInfo, setSubmitInfo] = useState("");

    useEffect(() => {

        // Vypnutí přesměrování při odeslání formuláře 
        const formElement = document.querySelector("#contact-form");
        formElement.addEventListener("submit", (event) => {
            event.preventDefault();
            const data = new URLSearchParams(new FormData(formElement));
            fetch("https://getform.io/f/7756cfaa-d8c5-40c3-9b06-fe7bc87bf01d", {
                method: "post",
                body: data
            });
            // Vypsání úspěšného odeslání formuláře
            setSubmitInfo("Zpráva byla úspěšně odeslána")

            // Reset všech polí formuláře po úspěšném odeslání
            formElement.reset();
        })
    },[]);

    return (
        <section>
                <div className="wrap-narrow pt-1 pb-8">

                    <h2 className="center">Kontaktujte nás</h2>
                    <form id="contact-form" className="form" action="https://getform.io/f/7756cfaa-d8c5-40c3-9b06-fe7bc87bf01d" method="POST">

                        <div className="form__two-fields">
                            <input type="text" name="name" placeholder="Jméno (vyžadováno)" required className="form__text-input"/>
                            <input type="text" name="surname" placeholder="Příjmení (vyžadováno)" required className="form__text-input"/>
                        </div>

                        <input type="text" name="phone" placeholder="Telefonní číslo (vyžadováno)" required className="form__text-input"/><br/>
                        <input type="email" name="mail" placeholder="E-mail (vyžadováno)" required className="form__text-input"/><br/>

                        <h3 className="mt-5 center">Co poptáváte</h3>
                    <div className="form__services">

                        <div className="form__services__button">
                            <label className="toggle">
                                <input type="checkbox" name="web-shop"/>
                                <div className="slide-toggle"></div>
                            </label> 
                            <p>Web / e-shop</p>
                        </div>

                        <div className="form__services__button">
                            <label className="toggle">
                                <input type="checkbox" name="graphics"/>
                                <div className="slide-toggle"></div>
                            </label>
                            <p>Grafiku</p>
                        </div>

                        <div className="form__services__button">
                            <label className="toggle">
                                <input type="checkbox" name="marketing"/>
                                <div className="slide-toggle"></div>
                            </label>
                            <p>Online marketing</p>
                        </div>

                    </div>

                    <h3 className="mt-5 center">Povězte nám o tom...</h3>
                    <textarea className="form__text-input" cols="30" rows="12" name="message" placeholder="Napište více o vašem projektu (rozsah, časový horizont, rozpočet atd...)"></textarea>
                
                    <div className="form__gdpr-check">
                        <input type="checkbox" name="gdpr" required/>
                        <p>Souhlasím se <Link to="/gdpr">zpracováním osobních údajů.</Link></p>
                    </div>

                        <button type="submit" className="btn-inverse-primary btn-large btn-fluid btn-icon-right">Odeslat<span className="material-icons-outlined">arrow_forward</span></button>
                     
                     </form>

                     <h5 className="form-submit-info">{submitInfo}</h5>

                 </div>
        </section>
    )
}